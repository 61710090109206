<template>

    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm(); $emit('hideModal', {})"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'? $t('label_edit'): $t('label_add')}}
        </template>

        <template #default="{ hide }">


        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >
            <validation-provider

                    #default="validationProps"
                    :name="$t('label_label_key')"
                    rules="required"
                    slim
            >
                <b-form-group
                        :label="$t('label_label_key')"

                >
                    <b-form-input
                            :disabled="action == 'editing'"
                            v-model="itemData.label_key"
                            :state="getValidationState(validationProps)"
                            :placeholder="$t('label_label_key')"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

                <validation-provider
                        v-for="lang in languages"
                        #default="validationProps"
                        :name="lang"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="lang"
                    >
                        <b-form-input
                                v-model="itemData[lang]"
                                :state="getValidationState(validationProps)"
                                placeholder=""
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>



            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_edit'):$t('label_add')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
        BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
    } from 'bootstrap-vue'

    import {MODULE_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            // vSelect
        },
        props:['editedItem', 'languages'],
        data() {
            return {

                PREFIX,

                action: 'adding',

                blankItemData: {
                    label_key:'',

                },

                itemData: {}

            }
        },

        methods: {

            initData(){

                let editedItem = this.editedItem;
                let item;
                if(editedItem){
                    this.action = 'editing';
                     item = Object.assign({},this.blankItemData);
                    item.label_key = editedItem.label_key;
                    for(let i = 0; i < this.languages.length; i++) {
                        item[this.languages[i]] = editedItem.trans[this.languages[i]];
                    }
                } else {
                    this.action = 'adding';
                    item =  Object.assign({},this.blankItemData)
                    for(let i = 0; i < this.languages.length; i++) {
                        item[this.languages[i]] = ''
                    }
                }


                this.itemData = item;

            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){
                let formData = new FormData();

                formData.append('label_key', this.itemData.label_key);
                for(let i = 0; i<this.languages.length; i++) {
                    formData.append('language['+this.languages[i]+']', this.itemData[this.languages[i]]);
                }

                if(this.action == 'adding') {
                    this.async('post', '/'+this.PREFIX, formData, function(resp){
                        this.$emit('item-added',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    },false,  {headers:{'Content-Type': 'multipart/form-data'}});

                } else {
                    this.async('post', '/'+this.PREFIX + '/1', formData, function(resp){
                        this.$emit('item-edited',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    },false,  {headers:{'Content-Type': 'multipart/form-data'}});

                }

            }
        },


        created (){
           // this.blankItemData.contact_id = this.$router.currentRoute.params.id;
        }
    }
</script>